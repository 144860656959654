
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.blogs-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 39px;
  font-style: normal;
  color: #002D65;
}

.popular-blogs {
    padding: 2em;
    text-align: center;
}

.blogs-container {
    display: flex;
    justify-content: center;
    gap: 1.5em;
    flex-wrap: wrap;
}

.blog-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 280px;
    transition: transform 0.3s;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-image img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
}

.blog-content {
    padding: 1em;
    text-align: left;
}

.blog-title {
    font-size: 1em;
    font-weight: 500;
    color: #333;
    margin: 0;
}

.blog-info {
    font-size: 0.9em;
    color: #777;
    margin-top: 0.5em;
}


.blogs-header {
    background-image: url('../images/blogs-up-bg.jpg'); /* Replace with your background image path */
    background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
}