
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



.testimonial-heading {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 40px;
  color: #013069;
}


.testimonial-container {
    padding: 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(to right, #d4a5f9, #b6d6f2);
    border-image-slice: 1;
    max-width: 700px;
    margin: 0 auto;
  }
  
  .testimonial-slide {
    text-align: center;
    padding: 20px;
  }
  
  .testimonial-text {
    font-size: 1.1rem;
    color: #555;
    position: relative;
    padding: 20px;
    line-height: 1.6;
    font-style: italic;
  }
  
  .quote-icon {
    font-size: 2rem;
    color: #ccc;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  
  .author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .testimonial-author h6 {
    margin: 0;
    font-weight: 600;
  }
  
  .testimonial-author span {
    color: #888;
    font-size: 0.9rem;
  }
  