
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.contact-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 38px;
  font-style: normal;
  color: #002D65;
  text-transform: uppercase;
}

.contact-form .form-control {
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  padding: 10px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.contact-form .form-control:focus {
  border-bottom-color: #002D65;
  box-shadow: none;
}

.submit-btn {
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
}

.contact-info {
  /* background-color: #f8f9fa; */
  border-radius: 8px;
}

.info-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.info-icon {
  font-size: 1.5rem;
  color: #002D65;
  margin-right: 10px;
}

.info-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #002D65;
  margin-bottom: 5px;
}

.info-card p, .info-card a {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.info-card a {
  color: #b90000;
  text-decoration: none;
}

.info-card a:hover {
  text-decoration: underline;
}

.social-icons {
  gap: 15px;
}

.social-link {
  font-size: 1.5rem;
  color: #b90000;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #333;
}

.map-container {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
