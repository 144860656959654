@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.service-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}




.blog-content {
    padding: 2em 0;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5em;
}

.blog-card {
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    background-color: #f5f5f5;
}

.blog-card-image {
    position: relative;
}

.blog-card-image img {
    width: 100%;
    height: auto;
    display: block;
}

.date-badge {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #FF0066;
    color: white;
    padding: 0.5em;
    font-size: 0.9em;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
}

.date-badge span {
    display: block;
}

.blog-card-content {
    padding: 1.5em;
}

.blog-meta {
    display: flex;
    gap: 1em;
    color: #777;
    font-size: 0.9em;
    margin-bottom: 0.5em;
}

.blog-card-content h3 {
    font-size: 1.3em;
    color: #333;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.blog-card-content p {
    color: #555;
    margin-bottom: 1em;
    font-size: 1em;
    line-height: 1.6;
}

.read-more-btn {
    display: inline-block;
    background-color: #FF0066;
    color: white;
    padding: 0.5em 1.5em;
    border-radius: 4px;
    font-size: 0.9em;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.read-more-btn:hover {
    background-color: #e6005c;
}

/* Responsive Design */
@media (max-width: 768px) {
    .blog-grid {
        grid-template-columns: 1fr;
    }
}



.services-header {
    background-image: url('../images/services-up-bg.avif'); /* Replace with your background image path */
    background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
}


.services-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
}

.header-content {
    position: relative;
    z-index: 1;
    text-align: center;
}

.header-title {
    font-size: 2.5em;
    font-weight: bold;
    margin: 0;
}

/* Breadcrumb styles */
.breadcrumb {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
  
  .breadcrumb-current,
  .breadcrumb-link {
    text-decoration: none;
    font-weight: bold;
    color: #6c757d; /* Default color */
    transition: color 0.3s, text-decoration 0.3s;
  }

.breadcrumb-current:hover {
    color:white
  }
  
  .breadcrumb-separator {
    margin: 0 0.5rem;
    color: white; /* Default separator color */
    font-weight: bold;
    
  }
  
  /* Active link styles */
  .breadcrumb-link.active {
    color: white; /* Active text color */
    font-weight: bold;
    text-decoration: underline; /* Underline active link */
  }
  
  /* Hover effect for non-active links */
  .breadcrumb-link:hover {
    color: rgba(255, 255, 255, 0.719); /* Change text color on hover */
    text-decoration: underline;
  }
  