@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.cta-section {
  height: 50vh; /* Set the height to 50vh for larger screens */
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  text-align: center;
  position: relative;
  padding: 0 20px; /* Padding on left and right */
  overflow: hidden; /* Prevent overflow */
}

.cta-content {
  z-index: 1;
  position: relative;
  max-width: 800px; /* Set a max width to prevent stretching on wide screens */
  width: 100%; /* Ensure content scales correctly */
}

.cta-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 2.5rem; /* Adjust font size for large screens */
  margin-bottom: 20px;
  color: #043570;
}

.cta-subtitle {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 30px;
  padding: 0 10px;
}

.get-started-button {
  background: linear-gradient(90deg, #002D65 0%, #002663 100%);
  color: #fff;
  font-weight: bold;
  font-size: 1em;
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px; /* Adds a gap between text and the button */
}

.get-started-button:hover {
  background-color: #1a2c53;
  color: #fff;
  transform: scale(1.05); /* Slightly enlarges button on hover */
}

/* Responsive for mobile devices */
@media (max-width: 768px) {
  .cta-section {
    height: auto; /* Remove fixed height for smaller screens */
    padding: 40px 20px; /* Reduce padding */
  }

  .cta-title {
    font-size: 2rem; /* Smaller title for mobile */
    margin-bottom: 15px;
  }

  .cta-subtitle {
    font-size: 1rem; /* Smaller subtitle for mobile */
    margin-bottom: 20px;
    padding: 0 20px; /* Remove excess padding on mobile */
  }

  .get-started-button {
    font-size: 1.1rem; /* Slightly larger font size for mobile */
    padding: 10px 25px; /* Adjust button padding */
    margin-top: 10px; /* Reduce space between text and button */
  }
}

/* Extra small mobile devices */
@media (max-width: 500px) {
  .cta-title {
    font-size: 1.5rem; /* Even smaller title for very small screens */
    margin-bottom: 10px;
  }

  .cta-subtitle {
    font-size: 0.9rem; /* Smaller subtitle */
    margin-bottom: 15px;
    padding: 0 10px; /* Smaller padding */

  }

  .get-started-button {
    font-size: 1rem; /* Smallest button font size */
    padding: 8px 20px; /* Smaller padding */
    margin-top: 8px; /* Reduced gap between text and button */
  }
}
