@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



.getstarted-title {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 40px;
  color: #013069;
}


.technology-section {
  color: white;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin: 40px auto;
}

.text-container {
  text-align: left;
  z-index: 2;
  position: relative;
}

.section-description {
  font-size: 16px;
  margin-bottom: 30px;
}

.explore-btn {
  background-color: #013168;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 10px 20px rgba(1, 65, 138, 0.4);
}

.explore-btn::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  opacity: 0.8;
}

.explore-btn:hover {
  box-shadow: 0px 15px 25px rgba(1, 65, 138, 0.4);
  transform: translateY(-3px);
}