/* src/css/Footer.css */
.footer {
    background-color: #001F3F;
    color: #FFFFFF;
    padding: 90px 0;
    font-family: Arial, sans-serif;
    height: auto;
  }

  
  .footer h5 {
    font-weight: bold;
    color: #FFFFFF;
    margin: 0; /* Remove any default margin */
    text-align: left; /* Ensure the heading is left-aligned */
}

.footer hr {
    background-color: white;
    width: 50px; /* Custom width */
    height: 5px; /* Custom height */
    border-radius: 2px;
    margin: 5px 0 15px 0; /* Adjust spacing around the hr */
    text-align: left; /* Align to the left */
}


.footer p,
.footer ul {
    text-align: left; /* Ensure left alignment */
    color: #D3D3D3;
}

.footer ul {
    padding-left: 0; /* Remove default padding */
    list-style: none; /* Remove bullet points */
}

.footer ul li {
    margin-bottom: 8px; /* Consistent spacing */
}

.footer ul li a {
    text-decoration: none;
    color: #D3D3D3;
}

.text-gray{
    color: #D3D3D3;
}

.menu-padding{
padding-left: 6rem;
}

.footer ul li a:hover {
    color: #FFFFFF;
    text-decoration: underline;
}

.footer .social-icons {
    gap: 10px; /* Ensure consistent spacing between icons */
}

.footer .social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #0D6EFD;
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: transform 0.3s;
}

.footer .social-icons a:hover {
    transform: scale(1.2);
}

@media (max-width: 500px) {
  .footer {
    padding: 90px 0; /* Set padding to 90px for mobile devices */
  }
}
