@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.hero-heading {
  font-size: clamp(1.8rem, 5vw, 3rem); /* Dynamically adjusts between 1.8rem and 3rem */
  margin-bottom: 10px;
  margin-left: 0; /* Remove negative margin */
}

.main-div div:first-child {
  color: white;
  text-align: left; /* Ensure left alignment for text */
}

.hero-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 19, 151, 0.5);
  z-index: 0;
}

.main-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
  /* margin-top:-50px; */
  /* border : 2px solid red; */

}

.main-div > div {
  flex: 1;
  min-width: 280px;
  margin: 20px;
}

.main-div div:first-child {
  color: white;
}

.hero-heading {
  font-size: 3rem;
  margin-bottom: 10px;
  margin-left: -40px;
}

.find-solution-button {
  background-color: #ffffff;
  color: #002D65;
  font-weight: bold;
  font-size: 1em;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 12px rgb(74, 111, 180);
}

.find-solution-button .arrow {
  font-size: 1.5em;
  margin-left: 8px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.find-solution-button:hover {
  box-shadow: 0px 4px 12px rgba(0, 98, 255, 0.3);
}

.find-solution-button:hover .arrow {
  transform: translateX(5px);
}

.dotlottie-player-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

@media (max-width: 768px) {
  .hero-heading {
    font-size: 1.8rem;
  }

  .main-div {
    flex-direction: column;
    text-align: center;
  }

  .main-div > div {
    margin: 10px 0;
  }

  .find-solution-button {
    font-size: 0.9em;
  }
}


.hero-heading {
  font-size: clamp(1.8rem, 5vw, 3rem); /* Dynamically adjusts between 1.8rem and 3rem */
  margin-bottom: 10px;
  margin-left: 0; /* Remove negative margin */
}

.main-div div:first-child {
  color: white;
  text-align: left; /* Ensure left alignment for text */
}

.find-solution-button {
  background-color: #ffffff;
  color: #002D65;
  font-weight: bold;
  font-size: 1em;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 12px rgb(74, 111, 180);
  margin: 0; /* Reset margin */
}

@media (max-width: 768px) {
  .hero-heading {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .main-div {
    flex-direction: column;
    text-align: center; /* Center-align all child elements */
  }

  .main-div div:first-child {
    text-align: center; /* Center-align text in smaller screens */
  }

  .main-div > div {
    margin: 10px 0;
  }

 
}

@media (max-width: 480px) {
  .hero-heading {
    font-size: 1.5rem; /* Further reduce font size for very small screens */
  }

  .find-solution-button {
    font-size: 0.8em;
  }
}
