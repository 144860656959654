.aboutus-header {
    background-image: url('../images/projects-up-bg.jpg'); /* Replace with your background image path */
    background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
}