@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.service-heading {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 40px;
  font-style: normal;
  color: #043570;
}

.service-wrapper {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
}

/* Gradient overlay */
.overlay-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 1;
  transition: all 0.5s ease;
}

.service-wrapper:hover .overlay-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.9) 100%);
}

/* Position icon in the top-left corner */
.icon-container {
  /* position: absolute; */
  position: relative;
  top: -30px;
  left: 30px;
  color: white;
  font-size: 1em;
  /* color: #0062ff; 
  z-index: 2; */
}

.icon{
  background-color: #003b9b;
  color: white;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 15px;
}

/* Title at the bottom */
.content {
  position: relative;
  z-index: 3;
  text-align: center;
  padding-bottom: 20px;
}

.title {
  color: #002663;
  margin: 60px 0;
  font-weight: bold;
  font-size: 1.25em;
  margin-bottom: 0;
  transition: all 0.5s ease;
}

.service-wrapper:hover .title {
  color: #adacab;
}

/* Box shadow and hover animation */
.service-wrapper:hover {
  box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
  transform: translateY(-8px);
}


.more-button {
  background: linear-gradient(90deg, #002D65 0%, #002663 100%);
  color: #fff;
  font-weight: bold;
  font-size: 1em;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.more-button .arrow {
  font-size: 1.8em;
  margin-left: 8px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.more-button:hover {
  box-shadow: 0px 4px 12px rgba(0, 98, 255, 0.3);
}

.more-button:hover .arrow {
  transform: translateX(5px); /* Animate the arrow to move slightly on hover */
}

@media (max-width: 500px) {
.card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  margin-left: 5%;
}

}



