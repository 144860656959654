@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar-nav .nav-item a {
    font-family: "Poppins", sans-serif;
}

header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it is above other elements */
    background-color: #002D62;
  }

/* Basic Navbar styling */
.navbar {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .navbar .nav-link {
    color: #333; /* Default link color */
    transition: color 0.3s ease;
  }
  
  .navbar .nav-link:hover {
    color: #002D62; /* Change link color on hover */
  }
  
  /* Active link styling */
  .navbar .nav-link.active {
    color: rgb(0, 81, 255) !important; /* Dark blue color for active link */
    font-weight: 400;
  }


  


  /* New */
  /* Hover effect for navbar links */
.nav-link:hover {
  background-color: #f1f1f1; /* Change to any color of your choice */
  border-radius: 5px;
}

/* Active link styling */
.nav-link.active {
  color: #002D62;
  font-weight: bold;
}

/* Adjust navbar items alignment on mobile */
@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
    justify-content: center; /* This will center items on mobile */
  }

  .text-truncate {
    display: inline-block;
    white-space: nowrap; 
    width: 167px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
} 

 
