

   @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


   .team-heading {
     font-family: "Roboto", sans-serif;
     font-weight: 500;
     font-size: 40px;
     font-style: normal;
     color: #002D65;
   }

   .team-card-container {
    position: relative;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
  
  .team-card {
    background: #ffffff;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 285px;
    overflow: hidden; /* Ensure content doesn't spill out */
    position: relative;
  }
  
  .team-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  }
  
  .team-card img {
    width: 255px;
    height: 265px;
    object-fit: cover;
    border: 2px solid #f1f1f1;
  }
  
  .team-title {
    font-size: 0.85rem;
    color: #666;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 30px;
  }
  
  .team-name {
    font-size: 1.2rem;
    color: #002D65;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .team-description {
    font-size: 0.9rem;
    color: #777;
    margin: 0.5rem 0 1.5rem;
  }
  
  /* Flipping section styles */
  .team-card-flip {
    position: absolute;
    bottom: 0; /* Aligns to the bottom */
    left: 0;
    width: 100%;
    height: 28.5%; /* Adjusted height for better visibility */
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }
  
  .team-card:hover .team-card-flip {
    transform: translateY(0%);
  }
  
  .team-card-flip a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .team-card-flip a:hover {
    color: #093a75;
  }
  