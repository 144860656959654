@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.section-title .whoweheading{
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 39px;
  font-style: normal;
  color: #043570;
}


.img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: darkblue;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
  
  .card:hover .img-overlay {
    opacity: 1;
  }
  
  .text-custom {
    color: #0453a7;
  }

  .who-para{
    font-weight: lighter;
    /* display: flex; */
    text-align: justify;
    font-size: 17px;
  }
  
  .lotie-ani1{
    height: 500px;
    width: 500px;
  }