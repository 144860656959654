.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Prevent body and html from overflowing */
/* html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;  
  overflow-y: hidden;  
} */

/* Ensure the container has full height without causing overflow */
/* .min-vh-100 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  overflow-x: hidden;  
  overflow-y: hidden;
} */


.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.card-body {
  transition: all 0.3s ease;
}


/* Custom CSS for better responsiveness */
@media (max-width: 576px) {
  .card {
    padding: 20px;
    width: 90%; /* Reduce the width on very small screens */
  }

  .form-control {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}



