/* Container */
.service-detail .section-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #002d62;
}

.service-detail .section-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.feature-item {
  font-size: 1rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-icon {
  color: #0056b3;
  font-size: 1.2rem;
}

/* Image Section */
.image-box {
  background: linear-gradient(135deg, #003985, #ff7961);
  padding: 1rem;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.logo-image {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background-color: #fff;
  padding: 5px;
}

/* Quality Industrial Working Section */
.quality-section {
  margin-top: 3rem;
}

.quality-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #002d62;
}

.quality-description {
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
}

/* Container */
.service-list-container {
  background-color: white;
  border-radius: 10px;
}

/* Link Styles */
.service-link {
  display: block;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #002d62;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.service-link:hover {
  background-color: white;
  color: #0056b3;
}

/* Active Link */
.service-link.active {
  background-color: #0056b3;
  color: white;
  border: none;
}

/* Arrow Icon Styling */
.service-link .fa-arrow-right {
  font-size: 0.9rem;
  color: inherit;
}
