
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.whatwedo-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 39px;
  font-style: normal;
  color: #002D65;
}

.whatwedo-para {
  font-size: 17px;
  text-align: justify;
}


.vision-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 39px;
  font-style: normal;
  color: #002D65;
}


.our-mission {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 39px;
  font-style: normal;
  color: #002D65;
}



.babysitting-container h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #333;
}

.babysitting-container p {
  font-size: 16px;
  color: #555;
}

.babysitting-container .btn-learn {
  background-color: #002D65;
  border-color: #002D65;
}

.babysitting-container .btn-learn:hover {
  background-color: #071b75;
  border-color: #071f86;
}

.feature-number {
  font-size: 36px;
  color: #071f86;
  font-weight: bold;
}

.feature-number + p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}
